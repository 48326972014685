import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import ExerciseEntry from "components/exercise/exercise_entry";

const Wrapper = styled.div`

`;

@observer
class ExerciseInputOptions extends React.Component {
  @computed get exercise() {
    return this.props.exercise || ExerciseUI.exercise;
  }

  render() {
    return (
      <Wrapper>
        {
          this.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && this.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                {...this.props}
              >
                {
                  item.prompt &&
                  <Prompt>
                    {item.prompt}
                  </Prompt>
                }
                {
                  item.sentence &&
                    <ExerciseSentence
                      inputType='select'
                      options={this.exercise.selectedUniqueWords}
                      sentence={item.sentence}
                      revealed={item.state.revealed}
                      hideLanguageButton={!item.showSource}
                    />
                }
              </ExerciseEntry>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseInputOptions;
