import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import styled from '@emotion/styled';
import Text from 'components/text';
import { isBlank, isPresent } from '@seedlang/utils';
import { FilterIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import WorksheetFilterOptions from 'components/worksheet/worksheet_filter_options';
import WorksheetFilterTags from 'components/worksheet/worksheet_filter_tags';
import autobind from 'autobind-decorator';
import SearchInput from 'components/form/search_input';
import { some, startCase } from "lodash";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: ${props => props.margin};
  height: fit-content;
  flex-wrap: wrap;
  row-gap: 8px;
  @media only screen and (max-width : 649px) {
    padding: ${props => props.mobilePadding};
  }
`;

const SearchWrapper = styled.div`
  flex: 1;
  margin: ${props => props.margin};
  input {
    border-radius: 30px;
    height: 40px;
  }
`;

const FilterWrapper = styled.div`
  margin: 1px 1px 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  border: 3px solid #CCC;
  border-radius: 5px;
  min-height: 46px;
  flex: 1;
`;

const FilterLabel = styled.div`
  width: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  height: 19px;
  display: flex;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FilterIconWrapper = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .icon-wrapper {
    height: 20px;
  }
  svg {
    fill: #333;
    width: 20px;
  }
`;

const SortWrapper = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border: 3px solid #CCC;
  border-radius: 5px;
  margin-left: 10px;
  background: #FFF;
  height: 44px;
  margin-top: -5px;
  padding: 10px;
  font-size: 12px;
  color: gray;
  .fa {
    font-size: 16px;
    color: black;
  }
`;

@observer
class WorksheetFilter extends React.Component {
  @observable showFilterOptions = false;
  @observable searchQuery = null;

  static defaultProps = {
    margin: '15px 0 10px 0',
    mobilePadding: '0px 10px',
  }

  @autobind onCloseModal() {
    this.showFilterOptions = false;
  }

  @computed get userGroupFilteredTagCategories() {
    return this.props.filteredTagCategories.filter(item => some(item.tags.map(tag => isPresent(AppUI.user.currentUserGroup?.worksheetTagIds) && AppUI.user.currentUserGroup?.worksheetTagIds?.split(',').indexOf(tag.id) !== -1)) || item.slug === 'progress' && isPresent(AppUI.user.currentUserGroup?.worksheetProgressTags));
  }

  @computed get postName() {
    if (isPresent(this.props.routeName) && this.props.routeName.startsWith('podcast')) {
      return 'Podcast Episode';
    } return 'Post';
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        mobilePadding={this.props.mobilePadding}
      >
        <SearchWrapper
          margin={this.props.searchQuery ? "1px 0 0 0" : "-4px 5px 0 0"}
        >
          <SearchInput
            hideSpecialCharacters
            placeholder={`Search for a ${this.postName}`}
            onChange={this.props.onSearchQueryChange}
            value={this.props.searchQuery}
            background="#FFF"
          />
        </SearchWrapper>
        {
          AppUI.layout.isMobile && !this.props.hideSort &&
            <SortWrapper
              onClick={this.props.toggleSort}
            >
              Sort by Date
              {
                this.props.sort === 'asc' &&
                <i className='fa fa-arrow-up'/>
              }
              {
                this.props.sort === 'desc' &&
                <i className='fa fa-arrow-down'/>
              }
            </SortWrapper>
        }
        {
          isBlank(this.props.searchQuery) && isPresent(this.props.filteredTagCategories) &&
            <FilterWrapper
              width={this.props.width}
            >
              {
                this.showFilterOptions &&
                  <Modal
                    width={AppUI.layout.isMobile ? '85%' : "400px"}
                    height={AppUI.layout.isMobile ? '85%' : "600px"}
                    onCloseModal={this.onCloseModal}
                    padding="0"
                    title="Filters"
                    contentShouldScroll
                    background={AppUI.site.secondaryAccentColor || AppUI.site.accentColor}
                  >
                    <WorksheetFilterOptions
                      onUpdateFilter={this.props.onUpdateFilter}
                      showProgressTag={this.props.showProgressTag}
                      tagCategories={this.props.filteredTagCategories}
                      {...this.props}
                    />
                  </Modal>
              }
              <FilterLabel
                onClick={() => this.showFilterOptions = true}
              >
                Filters
              </FilterLabel>
              <Center>
                {
                  isBlank(this.userGroupFilteredTagCategories) && (isBlank(AppUI.user.currentUserGroup?.worksheetProgressTags) || !this.props.showProgressTag) &&
                    <Text
                      onClick={() => this.showFilterOptions = true}
                      fontSize="12px"
                      lineHeight="15px"
                      color="#777"
                      cursor="pointer"
                      marginTop="-1px"
                    >
                      {`Filter ${this.postName+'s'}`}
                    </Text>
                }
                {
                  (this.userGroupFilteredTagCategories.length > 0 || this.props.showProgressTag) &&
                    <WorksheetFilterTags
                      onUpdateFilter={this.props.onUpdateFilter}
                      tagCategories={this.userGroupFilteredTagCategories}
                      showProgressTag={this.props.showProgressTag}
                    />
                }
              </Center>
              <FilterIconWrapper
                onClick={() => this.showFilterOptions = true}
              >
                <FilterIcon
                  width={20}
                  height={20}
                  fill="#444"
                />
              </FilterIconWrapper>
            </FilterWrapper>
        }
        {
          !AppUI.layout.isMobile && !this.props.hideSort &&
            <SortWrapper
              onClick={this.props.toggleSort}
            >
              Sort by Date
              {
                this.props.sort === 'asc' &&
                <i className='fa fa-arrow-up'/>
              }
              {
                this.props.sort === 'desc' &&
                <i className='fa fa-arrow-down'/>
              }
            </SortWrapper>
        }
      </Wrapper>
    );
  }
}

export default WorksheetFilter;
