import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentenceEnterInput from 'components/exercise/exercise_sentence_enter_input';
import styled from '@emotion/styled';
import ExerciseEntry from 'components/exercise/exercise_entry';
import Prompt from 'components/exercise/prompt';
import { observable } from "mobx";
const Wrapper = styled.div`

`;

@observer
class ExerciseSentenceEnter extends React.Component {
  @observable focusedSentenceId;

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && ExerciseUI.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                warningMessages={item.warningMessages}
                {...this.props}
              >
                {
                  item.prompt &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  item.sentence &&
                    <ExerciseSentenceEnterInput
                      revealOnEnter
                      exerciseEntry={item}
                      onFocus={() => this.focusedSentenceId = item.sentence.id}
                      isFocused={this.focusedSentenceId === item.sentence.id}
                      margin="0 20px 10px 0"
                      hideLanguageButton={!item.showSource}
                    />
                }
              </ExerciseEntry>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseSentenceEnter);
