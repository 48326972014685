import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import DownloadLinkTypeCreate from 'pages/creator/download_link_types/download_link_type_create';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import { isPresent } from '@seedlang/utils';
import Text from 'components/text';
import InfoTooltip from "components/info_tooltip";
import BackEndExplainer from '../back_end_explainer';

const Wrapper = styled.div`

`;

@observer
class DownloadLinkTypeIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getDownloadLinkTypes();
  }

  @autobind getDownloadLinkTypes() {
    AppUI.downloadLinkTypeStore.getIndex({});
  }

  @autobind onDelete(id) {
    AppUI.downloadLinkTypeStore.destroy({ids: {downloadLinkTypeId: id}}, this.getDownloadLinkTypes);
  }

  render () {
    return (
      <Wrapper>
        <BackEndExplainer>You can set up the type of download links that can be added to posts here.</BackEndExplainer>
        <DownloadLinkTypeCreate
          afterCreate={this.getDownloadLinkTypes}
        />
        <div className='row'>
          <div className='col-xs-12'>
            <table className="table-wrapper admin-table">
              <thead>
                <tr>
                  <th width="20" />
                  <th width="50">Pos</th>
                  <th width="200">Title</th>
                  <th width="200">Subtitle</th>
                  <th width="200">Comment <InfoTooltip>Only visible in the back-end, for admins</InfoTooltip></th>
                  <th width="50">Icon</th>
                  <th width="60"># Links</th>
                  <th>Memberships</th>
                  <th>Podcast</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {
                  AppUI.downloadLinkTypeStore.indexData.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{ name: 'creator.download_link_types.edit', params: { groupId: this.props.params.groupId, downloadLinkTypeId: item.id } }}
                            className="on-click"
                          >
                            <i className="fa fa-chevron-right fa-block" />
                          </Link>
                        </td>
                        <td>
                          <InPlaceText
                            defaultValue={item.position}
                            model="download_link_types"
                            field="position"
                            id={item.id}
                            afterChange={this.getDownloadLinkTypes}
                          />
                        </td>
                        <td>
                          {item.title}
                        </td>
                        <td>
                          {item.subtitle}
                        </td>
                        <td>
                          {item.comment}
                          {item.generatedPdfs && <Text fontSize='12px'> PDF worksheets are added automatically added here.</Text>}
                        </td>
                        <td>
                          {
                            isPresent(item.icon) &&
                              <i className={`fa fa-${item.icon}`} />
                          }
                        </td>
                        <td>{item.downloadLinksCount}</td>
                        <td>
                          {isPresent(item.membershipTypeIds) ? item.membershipTypeIds.split(",").length : 0} Selected
                        </td>
                        <td>
                          { isPresent(item.podcast) && item.podcast.title }
                        </td>
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message="Delete this record?"
                            className="fa-block right"
                          />
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DownloadLinkTypeIndex;
