import React from 'react';
import { observer } from 'mobx-react';
import { Theme } from '@seedlang/constants';
import { isBlank } from '@seedlang/utils';
import cx from 'classnames';
import styled from '@emotion/styled';
import { WordBreakdownWrapper } from '@seedlang/hoc';
import ReactTooltip from 'react-tooltip';
import { computed } from "mobx";

const Wrapper = styled.span`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
`;

const CharWrapper = styled.span`
  border-bottom: ${(props) => (props.editable || props.selected) ? '2px solid ' : null};
  border-color: ${(props) => props.selected ? Theme.orange : Theme.gray };
  margin-right: ${(props) => props.marginRight};
  cursor: ${(props) => props.cursor};
`;

@observer
class WordBreakdown extends React.Component {

  @computed get word() {
    if (this.props.card) {
      return this.props.card.word;
    } return this.props.word;
  }

  @computed get editText() {
    if (this.props.word) {
      return this.props.word.targetText;
    } if (this.props.card) {
      return this.word.targetTextWithDefiniteArticle;
    }
  }

  @computed get displayText() {
    if (this.props.displayText) {
      return this.props.displayText;
    } return this.word.targetTextWithDefiniteArticle;
  }

  @computed get indexDifference() {
    return this.displayText.indexOf(this.editText);
  }

  render() {
    return (
      <Wrapper
        fontSize={this.props.editMode ? "20px" : null}
        color={this.props.color}
      >
        {
          this.props.editMode && this.word && this.editText.split("").map((char, index) => {
            return (
              <CharWrapper
                selected={this.props.selectedCharacters?.indexOf(index) !== -1}
                key={`${this.word.id}-${index}`}
                onClick={() => this.props.onClick(index)}
                marginRight={"2px"}
                editable={true}
                cursor="pointer"
              >
                {char}
              </CharWrapper>
            )
          })
        }
        {
          !this.props.editMode && this.word && this.displayText.split("").map((char, index) => {
            return (
              <CharWrapper
                selected={this.props.charIsSelected(index-this.indexDifference)}
                key={`${this.word.id}-${index}`}
                editable={false}
              >
                {char}
              </CharWrapper>
            )
          })
        }
        {
          this.props.editable &&
            <span>
              <span
                data-for={`edit-${this.word?.id}`}
                data-tip
              >
                <i
                  style={{marginLeft: 10, marginTop: 3, color: 'gray'}}
                  className={cx('fa', this.props.editMode ? 'fa-save' : 'fa-underline', 'fa-on-click', {
                    selected: this.props.editMode
                  })}
                  onClick={this.props.toggleEditMode}
                />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`edit-${this.word?.id}`}
                  className="custom-tooltip"
                >
                  { this.props.word ? 'Underline stressed characters' : 'Underline characters in target text' }
                </ReactTooltip>
              </span>
              {
                !isBlank(this.props.underlinedCharacters) && !this.props.editMode &&
                  <span
                    data-for={`clear-${this.word?.id}`}
                    data-tip
                  >
                    <i
                      style={{marginLeft: 10, marginTop: 3}}
                      onClick={this.props.clearUnderlinedCharacters}
                      className={`fa fa-times fa-on-click ${this.props.className}`}
                    />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id={`clear-${this.word?.id}`}
                      className="custom-tooltip"
                    >
                      Clear underlined characters
                    </ReactTooltip>
                  </span>
              }
            </span>
        }
      </Wrapper>
    )
  }
}

export default WordBreakdownWrapper(WordBreakdown);
