import React from 'react';
import { observer } from 'mobx-react';
import { observable } from "mobx";
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import ExerciseEntry from 'components/exercise/exercise_entry';

const Wrapper = styled.div`

`;

@observer
class ExerciseInputEnter extends React.Component {
  @observable focusedSentenceId;

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.exercise.exerciseEntries.map(item => {
            console.log('item.showSource', item.showSource);
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && ExerciseUI.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                warningMessages={item.warningMessages}
                {...this.props}
              >
                {
                  item.prompt &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  item.sentence &&
                    <ExerciseSentence
                      inputType='text'
                      onFocus={() => this.focusedSentenceId = item.sentence.id}
                      isFocused={this.focusedSentenceId === item.sentence.id}
                      sentence={item.sentence}
                      revealed={item.state.revealed}
                      hideLanguageButton={item.sentence && item.sentence.defaultSource && item.sentence.defaultSource.text === item.prompt && !item.showSource}
                      onEnter={() => ExerciseUI.revealExerciseEntryAnswer(item)}
                      showAlternateTextFeedback={item.state.correct && item.state.submittedValueMatchesAlternateText}
                    />
                }
              </ExerciseEntry>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseInputEnter;
