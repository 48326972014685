import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import { isPresent } from "@seedlang/utils";
import WordBreakdown from "components/word_breakdown";
import styled from '@emotion/styled';


const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
  display: flex;
  flex-wrap: wrap;
`;


@observer
class WorksheetVocabTargetText extends React.Component {

  @autobind wordTargetText(item) {
    if (isPresent(item.wordStructure) && isPresent(item.wordStructure.text)) {
      if (AppUI.layout.isMobile && isPresent(item.wordStructure.shortText)) {
        if (!AppUI.site.showWordPages) {
          return item.wordStructure.shortText;
        }
        return item.wordStructure.shortText.split(' ').map(token => token === item.word.targetText ? `<span style="border-bottom: 2px solid #b5b5b5;">${token}</span>` : token).join(' ');
      } else {
        if (!AppUI.site.showWordPages) {
          return item.wordStructure.text;
        }
          return item.wordStructure.text.split(' ').map(token => item.word.targetText.includes(token) ? `<span style="border-bottom: 2px solid #b5b5b5;">${token}</span>` : token).join(' ');
      }
    }
    if (AppUI.site.hideDefiniteArticle) {
      return item.word.targetTextWithGender;
    } else {
      return item.word.targetTextWithDefiniteArticle;
    }
  }

  render() {
    return (
      <Wrapper
        fontSize={this.props.worksheetWord.word?.rightToLeftLanguage ? "20px" : "16px"}
      >
        {
          !AppUI.site.showWordStress &&
            <div
                dangerouslySetInnerHTML={{__html: this.wordTargetText(this.props.worksheetWord)}}
            />
        }
        {
          AppUI.site.showWordStress &&
            <WordBreakdown word={this.props.worksheetWord.word} displayText={this.wordTargetText(this.props.worksheetWord)} />
        }
        {
          AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNounsString) &&
          <div style={{marginRight: '4px', color: 'gray'}}>,</div>
        }
        {
          !AppUI.site.showWordStress && AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNounsString) &&
            <div style={{color: 'gray'}}>{this.props.worksheetWord.word?.pluralNounsString}</div>
        }
        {
          AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNoun) && AppUI.site.showWordStress &&
            <>
              <WordBreakdown color="gray" word={this.props.worksheetWord.word.pluralNoun} displayText={this.props.worksheetWord.word?.pluralNoun.targetTextWithDefiniteArticle} />
            </>
        }
      </Wrapper>              
    );
  }
}

export default WorksheetVocabTargetText;
