import React from 'react';
import { observer } from 'mobx-react';
import LanguageButton from 'components/button/language_button';
import SentenceBreakdown from 'components/sentence_breakdown';
import { DeckUI, AppUI } from '@seedlang/state';
import CardTextSource from 'components/card/card_text_source';
import { pixify, depixify } from '@seedlang/utils';
import TextResizer from 'components/text_resizer';
import Text from 'components/text';
import { RevealSubtitlesIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { CardTextWrapper, BorderRadius } from '@seedlang/hoc';
import HeightLabel from 'components/height_label';
import TranslateSentenceIcon from "components/icons/translate_sentence_icon";

const Wrapper = styled.div`
  z-index: ${Theme.z["card-3"]};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  cursor: default;
  width: 100%;
`;

const LanguageButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: ${props => props.width};
  height: ${props => props.height};
  background: #FFF;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width || '100%'};
  left: ${(props) => props.left || 0};
  background: ${(props) => props.background || "#e8e8e8"};
  position: ${(props) => props.position || 'absolute'};
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

@observer
class CardText extends React.Component {

  static defaultProps = {
    languageButtonTop: "10px",
    languageButtonRight: "10px",
    position: 'relative',
  }

  render() {
    return (
      <Wrapper
        position={this.props.position}
        height={pixify(this.props.height)}
      >
        {
          DeckUI.layout.debug &&
            <HeightLabel
              left={0}
            >
              {this.props.height}
            </HeightLabel>
        }
        <TextWrapper
          hasUserInput={this.props.hasUserInput}
          padding={this.props.padding}
          margin={this.props.margin}
          height={pixify(this.props.height)}
          width={this.props.width}
          position={this.props.position}
          borderTopLeftRadius={pixify(this.props.borderTopLeftRadius)}
          borderTopRightRadius={pixify(this.props.borderTopRightRadius)}
          borderBottomLeftRadius={pixify(this.props.borderBottomLeftRadius)}
          borderBottomRightRadius={pixify(this.props.borderBottomRightRadius)}
          left={this.props.left}
          background={this.props.background}
        >
          {
            !this.props.showText && this.props.showRevealTextIcon && DeckUI.userInputStores[this.props.card.id]?.inputType !== 'text' && !this.props.hideButtons &&
              <RevealSubtitlesIcon
                onClick={this.props.revealText}
                width={DeckUI.layout.minimumHeight ? 30 : 40}
                height={DeckUI.layout.minimumHeight ? 30 : 40}
              />
          }
          {
            this.props.cardTypeSlug === 'sentence_pronounce' && !this.props.cardState.revealed && this.props.showText && this.props.showType === 'target' &&
            <Text
              italic
              fontSize="16px"
            >
              Read the sentence out loud
            </Text>
          }
          {
            this.props.showEdit && this.props.showText && !this.props.hideButtons &&
              <TranslateSentenceIcon
                card={this.props.card}
                top="5px"
              />
          }
          {
            this.props.simpleTextMode && this.props.showType === 'target' && this.props.showText &&
              <TextResizer
                resizeText
                height={depixify(this.props.height) - 20}
                width={depixify(this.props.width || DeckUI.layout.innerDeckWidth) - 40}
                characterLength={this.props.card && this.props.card.translation && this.props.card.translation.targetText && this.props.card.translation.targetText.length}
              >
                <Text
                  padding="0"
                  center
                >
                  {this.props.sentence?.targetText}
                </Text>
              </TextResizer>
          }
          {
            !this.props.simpleTextMode && this.props.showText && !this.props.showSentenceBreakdown && this.props.showType === 'target' &&
              <TextResizer
                resizeText
                height={depixify(this.props.height) - 20}
                width={depixify(this.props.width || DeckUI.layout.innerDeckWidth) - 40}
                characterLength={this.props.customText && this.props.customText.length}
              >
                <Text
                  padding="0"
                  center
                >
                  {this.props.customText}
                </Text>
              </TextResizer>
          }
          {
            !this.props.simpleTextMode && this.props.showText && this.props.showSentenceBreakdown && this.props.showType === 'target' && this.props.sentence && (this.props.sentence.enrichedWords || this.props.wordsAreEnriched) &&
              <TextResizer
                resizeText
                height={depixify(this.props.height) - 20}
                width={depixify(this.props.width || DeckUI.layout.innerDeckWidth) - 80}
                characterLength={this.props.sentence && this.props.sentence.targetText && this.props.sentence.targetText.length}
              >
                <SentenceBreakdown
                  center
                  showUnderline
                  blockHighlightOnClick
                  blockHighlightConcept
                  showCase={this.props.sentence.state.selectedWordAssociationId}
                  onOpenArrowBox={this.props.onOpenArrowBox}
                  hideHighlight={DeckUI.reviewDeck}
                  highlightConceptId={DeckUI.deck.conceptId}
                  highlightWords={DeckUI.deck.words}
                  highlightWordAssociationIds={this.props.card.cardWordAssociations.map(item => item.wordAssociationId)}
                  sentence={this.props.sentence}
                />
              </TextResizer>
          }
          {
            this.props.showText && !this.props.showHelp && this.props.showType === 'source' &&
              <CardTextSource
                resizeText={this.props.cardTypeSlug !== 'translate'}
                height={this.props.cardState.revealed ? DeckUI.layout.cardTextHeight : 200}
                width={depixify(this.props.width || DeckUI.layout.innerDeckWidth) - 40}
                translateToGerman={this.props.cardTypeSlug === 'translate' && !this.props.cardState.revealed}
                text={this.props.sourceText}
                showLiteralSource={this.props.showLiteralSource}
                literalSource={this.props.literalSource}
                user={DeckUI.user}
              />
          }
          {
            this.props.showHelp &&
              <TextResizer
                resizeText
                height={depixify(this.props.height) - 20}
                width={depixify(this.props.width || DeckUI.layout.innerDeckWidth) - 40}
                characterLength={this.props.sentence && this.props.sentence.targetText && this.props.sentence.targetText.length}
              >
                <SentenceBreakdown
                  center
                  showUnderline
                  display="source"
                  sentence={this.props.sentence}
                  blockHighlightOnClick
                  hideHighlight
                  onClick={this.props.onClickHelpWord}
                  hideBackground
                />
              </TextResizer>
          }
        </TextWrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default BorderRadius(CardTextWrapper(CardText));
