import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { FlagTranslationIcon } from '@seedlang/icons';
import Confirmation from "../confirmation";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: ${props => props.margin};
`;

@observer
class FlagButton extends React.Component {
  @observable showConfirmation = false;

  constructor(props) {
    super();
    this.id = Math.random();
  }
  static defaultProps = {
    width: "20px",
    faIcon: "fa-times",
  }

  @autobind onConfirm() {
    this.showConfirmation = false;
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.width}
      >
        {
        this.showConfirmation &&
          <Confirmation
            left={this.props.left}
            right={this.props.right}
            top={this.props.top}
            floatsRight={this.props.confirmationMessageFloatsRight}
            close={() => this.showConfirmation = false}
            onConfirm={this.onConfirm}
            message={this.props.flagged ? "Remove Flagging of this Translation?" : "Flag Incorrect Translation"}
          />
        }
        <FlagTranslationIcon
          minimumHeight="20px"
          onClick={() => this.showConfirmation = true}
          showTooltip
          message={this.props.flagged ? "Remove Flagging of this Translation?" : "Flag Incorrect Translation"}
          flagged={this.props.flagged}
        />
      </Wrapper>
    );
  }
}

export default FlagButton;
