import React from 'react';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import Checkbox from 'components/checkbox';
import { isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  padding: 20px;
`;

const TagCategory = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const TagCategoryWrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

const TagCategoryName = styled.div`
  font-weight: bold;
`;

const TagWrapper = styled.div`

`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: whitesmoke;
  }
`;

const Name = styled.div`
  flex: 1;
  font-size: 16px;
`;

const CheckboxWrapper = styled.div`
  width: 20px;
`;

@observer
class WorksheetFilterOptions extends React.Component {
  @observable showSpinner = false;
  @observable worksheetTagIds = "";
  @observable worksheetProgressTags = "";

  constructor(props) {
    super(props);
    this.showSpinner = true;
    autorun(() => {
      if (AppUI.userIsLoaded) {
        this.worksheetTagIds = AppUI.user.currentUserGroup.worksheetTagIds || '';
        this.worksheetProgressTags = AppUI.user.currentUserGroup.worksheetProgressTags || '';
      }
    });
  }

  @autobind onClickTag(tagId) {
    let ary = isPresent(this.worksheetTagIds) ? this.worksheetTagIds.split(",") : [];
    if (this.tagIsSelected(tagId)) {
      ary = ary.filter(item => item !== tagId);
    } else {
      ary.push(tagId);
    }
    const stringValue = ary.join(",");
    this.worksheetTagIds = stringValue;
    AppUI.user.currentUserGroup.set('worksheetTagIds', stringValue);
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup.id}, data: {worksheetTagIds: stringValue}}, this.afterUpdateTags);
  }

  @autobind afterUpdateTags() {
    AppUI.loadUser();
    this.props.onUpdateFilter();
  }

  @autobind tagIsSelected(tagId) {
    return this.worksheetTagIds?.split(",").indexOf(tagId) !== -1;
  }

  @autobind progressTagIsSelected(tag) {
    return isPresent(this.worksheetProgressTags) && this.worksheetProgressTags.split(",").indexOf(tag.slug) !== -1;
  }

  @autobind onClickProgressTag(tag) {
    let ary = isPresent(this.worksheetProgressTags) ? this.worksheetProgressTags.split(",") : [];
    if (this.progressTagIsSelected(tag)) {
      ary = ary.filter(item => item !== tag.slug);
    } else {
      ary.push(tag.slug);
    }
    const stringValue = ary.join(",");
    this.worksheetProgressTags = stringValue;
    AppUI.user.currentUserGroup.set('worksheetProgressTags', stringValue);
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup.id}, data: {worksheetProgressTags: stringValue}}, this.afterUpdateTags);
  }

  render () {
    return (
      <Wrapper>
        {
          this.props.tagCategories.filter(item => item.published).map(item => {
            if (item.slug === 'progress' && this.props.showProgressTag) {
              return (
                <TagCategoryWrapper
                  key={item.id}
                >
                  <TagCategory>
                    <TagCategoryName>
                      {item.name}
                    </TagCategoryName>
                  </TagCategory>
                  <TagWrapper>
                    {
                      item.tags.map(tag => {
                        return (
                          <FilterOption
                            key={tag.id}
                            onClick={() => this.onClickProgressTag(tag)}
                          >
                            <Name>
                              {tag.name}
                            </Name>
                            <CheckboxWrapper>
                              <Checkbox
                                value={this.progressTagIsSelected(tag)}
                              />
                            </CheckboxWrapper>
                          </FilterOption>
                        )
                      })
                    }
                  </TagWrapper>
                </TagCategoryWrapper>
              )
            } else if (item.slug !== 'progress') {
              return (
                <TagCategoryWrapper
                  key={item.id}
                >
                  <TagCategory>
                    <TagCategoryName>
                      {item.name}
                    </TagCategoryName>
                  </TagCategory>
                  <TagWrapper>
                    {
                      item.tags.filter(item => item.published).map(tag => {
                        return (
                          <FilterOption
                            key={tag.id}
                            onClick={() => this.onClickTag(tag.id)}
                          >
                            <Name>
                              {tag.name}
                            </Name>
                            <CheckboxWrapper>
                              <Checkbox
                                value={this.tagIsSelected(tag.id)}
                              />
                            </CheckboxWrapper>
                          </FilterOption>
                        )
                      })
                    }
                  </TagWrapper>
                </TagCategoryWrapper>
              )
            }
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetFilterOptions);
