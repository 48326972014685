import React from 'react';
import { observer } from 'mobx-react';
import { CardSourceListWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import SourceList from 'components/source/source_list';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { PencilIcon } from '@seedlang/icons';
import { AppUI, DeckUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';
import LanguageButton from "components/button/language_button";
import { isPresent } from "@seedlang/utils/src";
import Answer from "components/vocab_card/answer";

const Wrapper = styled.div`
  background: ${(props) => props.background};
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SourceListWrapper = styled.div`
  overflow: auto;
  flex: 1;
  margin-bottom: ${props => props.marginBottom};
`;

const PencilIconWrapper = styled.div`
  ${flexCenterColumn()}
  cursor: pointer;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  background: #FFF;
  .pencil-icon {
    height: 16px;
    margin: -2px -1px 0 0;
    svg {
      width: 16px;
      fill: #333;
    }
  }
`;

const IconsWrapper = styled.div`
  ${flexCenterColumn()}
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  gap: 5px;
`;

const LanguageButtonWrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background: #FFF;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

@observer
class CardSourceList extends React.Component {

  static defaultProps = {
    sourcePadding: "10px" ,
  }

  render() {
    return (
      <Wrapper
        background={this.props.background}
      >
        <IconsWrapper>
          {
            this.props.showLanguageButton &&
              <LanguageButtonWrapper
                onClick={this.props.toggleLanguage}
                height={DeckUI.layout.minimumHeight ? "30px" : "40px"}
                width={DeckUI.layout.minimumHeight ? "30px" : "40px"}
              >
                <LanguageButton
                  background="#e8e8e8"
                  arrowLeft={this.props.arrowLeft}
                  width={this.props.languageIconWidth}
                  languageId={this.props.showType === 'source' ? this.props.sentence?.sourceTextForUserLanguageId(DeckUI.user) || 'EN' : DeckUI.deck?.languageId || this.props.card?.languageId || DeckUI.targetLanguageId}
                />
              </LanguageButtonWrapper>
          }
          {
            this.props.showEdit &&
              <PencilIconWrapper
                width={DeckUI.layout.minimumHeight ? "30px" : "40px"}
                className='pencil-icon-wrapper'
                onClick={this.props.onToggleSourceEditExpanded}
              >
                <PencilIcon />
              </PencilIconWrapper>
          }
        </IconsWrapper>

        <SourceListWrapper
          marginBottom={this.props.showWrongAnswer && isPresent(this.props.wrongAnswer) ? '50px' : 0}
        >
          <SourceList
            {...this.props}
            showFlag={AppUI.user.languageId !== 'EN'}
            flagWrapperWidth="25px"
            flagWrapperMargin="0 0 0 5px"
            borderBottom="1px solid #909090"
            showAddSource
            hideRetranslationIcon
            sourcePadding={this.props.sourcePadding}
            fontSize={pixify(this.props.fontSize)}
            translations={this.props.translations}
            hideGender={this.props.hideGender}
            user={AppUI.user}
            preferredSource={this.props.card.source}
            onClick={this.props.toggleLanguage}
            numberPreviewSources={this.props.numberPreviewSources}
          />
        </SourceListWrapper>
        {
          this.props.card.cardState?.revealed && this.props.showWrongAnswer && isPresent(this.props.wrongAnswer) &&
            <Answer
              text={this.props.wrongAnswer}
              width='100%'
            />
        }
      </Wrapper>
    );
  }
}

export default CardSourceListWrapper(CardSourceList);
