import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import LanguageButton from 'components/button/language_button';
import InPlaceSelect from 'components/form/in_place_select';
import ReorderAcceptItem from 'components/exercise/reorder_accept_item';
import { isBlank, isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI, ExerciseUI } from '@seedlang/state';
import SpecialCharacterButtons from "components/special_character_buttons";
import autobind from "autobind-decorator";
import Text from "components/text";
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const SentenceWrapper = styled.div`
  line-height: ${props => props.lineHeight};
  margin: 5px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  flex-wrap: wrap;
  input:focus {
    border: none;
    border-bottom: 1px solid black;
  }
  input {
    background: white;
    height: 40px;
    width: 100px;
    font-size: 18px;
    line-height: 15px;
    padding: 5px;
    flex: 1;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid black;
  }
  select {
    height: 40px;
  }
  .in-place-select {
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const WordWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: ${(props) => props.margin || "0 5px 0 0"};
  input, select {
    background: ${(props) => props.inputBackground};
    color: ${(props) => props.inputBackground ? '#FFF' : '#000'};
  }
`;

const LanguageButtonWrapper = styled.div`
    height: 40px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const Punctuation = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin: 0 1px;
`;

const Revealed = styled.div`
  background: ${(props) => props.correct ? Theme.green : Theme.red};
  color: white;
  min-width: 100px;
  padding: 0 5px;
`;

const HighlightedWord = styled.div`
  padding: 0 5px;
  cursor: pointer;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  &:hover {
    background: ${(props) => props.submitted ? "#f9f91b" : "#e6e6e6"};
  }
`;

const NotSelected = styled.div`
  height: ${(props) => props.height}!important;
  display: flex;
  align-items: center;
`;

const CharacterWrapper = styled.div`
  margin-top: 10px;
`;

@observer
class ExerciseSentence extends React.Component {
  @observable languageId = this.props.sentence.languageId;
  @observable currentInputWordAssociation;

  @computed get wordAssociations() {
    if (isPresent(this.props.sentence.wordAssociations)) {
      const reverseText = (AppUI.layout.isNative && AppUI.user.mobileTextDirection === 'reverse') || (!AppUI.layout.isNative && this.props.sentence?.rightToLeftLanguage) || (AppUI.layout.isNative && this.props.sentence?.rightToLeftLanguage && AppUI.user.mobileTextDirection !== 'forward');
      return reverseText ? this.props.sentence.wordAssociations.slice().reverse() : this.props.sentence.wordAssociations;
    }
    return null;
  }

  @autobind insertText(text) {
    if (isBlank(this.currentInputWordAssociation)) { return null }
    const el = document.getElementById(this.currentInputWordAssociation.id);
    const caretPosition = el.selectionStart;
    el.focus();
    const newValue = [this.currentInputWordAssociation.state.submittedValue?.slice(0, caretPosition), text, this.currentInputWordAssociation.state.submittedValue?.slice(caretPosition)].join('');
    this.currentInputWordAssociation.state.set('submittedValue', newValue);
  }

  clickHighlight(item, index) {
    item.state.set('submittedValue', !item.state.submittedValue);
  }

  inputBackground(wordAssociation) {
    if (!this.props.revealed) { return null }
    return wordAssociation.state.correct ? Theme.green : Theme.red;
  }

  @autobind onFocus(wordAssociation) {
    this.currentInputWordAssociation = wordAssociation;
    this.props.onFocus();
  }

  @autobind onKeyUpTextInput(e) {
    if (e.keyCode === 13 && this.props.onEnter) {
      this.props.onEnter();
    }
  }

  @autobind _revealedText(item) {
    if (item.state.correct
      && item.state.entryHasUserInput
      && item.adjustedTargetTextForExercise((ExerciseUI.exercise)).trim()?.length === item.state.submittedValue.trim()?.length
      && ((item.state.incorrectCasing && !ExerciseUI.site.requireCorrectCase)
        || (item.state.incorrectSpecialCharacters && !ExerciseUI.site.requireCorrectSpecialCharacters))) {
      return item.adjustedTargetTextForExercise((ExerciseUI.exercise))?.trim().split('').map((char, index) => char !== item.state.submittedValue?.trim()[index] ? `<u>${char}</u>` : char).join('');
    } if (item.state.correct
      && !(item.state.incorrectCasing && ExerciseUI.site.requireCorrectCase)
      && !item.state.submittedValueMatchesAlternateText()) {
      return item.adjustedTargetTextForExercise(ExerciseUI.exercise);
    }
    return item.state.submittedValue;
  }

  @autobind highlightedWordBackground(item) {
    if (item.state.submittedValue && !this.props.revealed) {
      return "#f9f91b";
    } if (item.state.submittedValue && item.selected) {
      return Theme.green;
    } if (item.state.submittedValue) {
      return Theme.red;
    }
    return "#FFF";
  }

  @autobind highlightedWordColor(item) {
    if (this.props.revealed && item.state.submittedValue) {
      return "#FFF";
    }
  }

  render() {
    return (
      <Wrapper
        lineHeight={this.props.inDeck ? '50px' : '30px'}
      >
        <SentenceWrapper
          justifyContent={this.props.justifyContent}
        >
          {
            !this.props.hideLanguageButton && this.props.sentence.defaultSource && this.props.sentence.defaultSource.text && 
              <LanguageButtonWrapper>
                <LanguageButton
                  languageId={this.languageId}
                  onClick={() => this.languageId = this.languageId === this.props.sentence.languageId ? 'EN' : this.props.sentence.languageId}
                />
              </LanguageButtonWrapper>
          }
          { this.languageId === 'EN' && this.props.sentence.defaultSource.text }
          { isPresent(this.props.sentence) && this.languageId === this.props.sentence.languageId && (this.wordAssociations?.length === 0 || this.props.showTargetText) && this.props.sentence.targetText}
          {
            isPresent(this.props.sentence) && this.languageId === this.props.sentence.languageId && !this.props.showTargetText && this.wordAssociations?.length > 0 && this.wordAssociations.map((item, index) => {
              return (
                <WordWrapper
                  key={item.id}
                  margin={this.props.wordMargin}
                  inputBackground={this.inputBackground(item)}
                >
                  {
                    (item.precedingPunctuation || (item.punctuation && this.props.sentence?.rightToLeftLanguage)) &&
                      <Punctuation>
                        {item.punctuation && this.props.sentence?.rightToLeftLanguage ? item.punctuation : item.precedingPunctuation}
                      </Punctuation>
                  }
                  {
                    item.selected && !this.props.revealed && this.props.inputType === 'text' &&
                      <input
                        type='text'
                        id={item.id}
                        value={item.state.submittedValue || ''}
                        onChange={(e) => item.state.set('submittedValue', e.currentTarget.value)}
                        onKeyUp={this.onKeyUpTextInput}
                        onFocus={() => this.onFocus(item)}
                      />
                  }
                  {
                    item.selected && this.props.inputType === 'select' &&
                      <InPlaceSelect
                        allowUpdate
                        includeBlank
                        value={item.state.submittedValue || ''}
                        options={this.props.options}
                        disabled={this.props.revealed}
                        onChange={(val) => item.state.set('submittedValue', val)}
                        style={item.firstInSentence && (!ExerciseUI.hasExercise || ExerciseUI.exercise.capitalizeEntries) ? {textTransform: 'capitalize'} : null}
                      />
                  }
                  {
                    item.selected && this.props.inputType === 'reorder' &&
                      <ReorderAcceptItem
                        height={this.props.inDeck ? '50px' : '40px'}
                        correct={this.props.revealed && item.state.correct}
                        incorrect={this.props.revealed && !item.state.correct}
                        disabled={this.props.revealed}
                        capitalize={(index === 0 || ['.','?','!'].indexOf(this.wordAssociations[index-1]?.punctuation) !== -1) && (!ExerciseUI.hasExercise || ExerciseUI.exercise.capitalizeEntries)}
                        value={item.state.submittedValue || ''}
                        onClick={() => ExerciseUI.clearWordAssociation(item, this.props.sentence)}
                        fontSize={Constants.RTL_LANGUAGE_IDS.indexOf(this.languageId) !== -1 ? '16px' : '14px'}
                        id={item.id}
                        minWidth={ExerciseUI.layout.isMobile && !this.props.inDeck ? '60px' : '100px'}
                        inDeck={this.props.inDeck}
                      />
                  }
                  {
                    item.selected && this.props.revealed && this.props.inputType === 'text' &&
                      <Revealed
                        correct={ExerciseUI.site.requireCorrectCase ? item.state.correctCaseSensitive : item.state.correct}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: this._revealedText(item) }}
                        />
                      </Revealed>
                  }
                  {
                    this.props.inputType === 'highlight' &&
                      <HighlightedWord
                        submitted={item.state.submittedValue}
                        onClick={() => this.clickHighlight(item, index)}
                        background={this.highlightedWordBackground(item)}
                        color={this.highlightedWordColor(item)}
                      >
                        {index === 0 && this.props.firstWordIsLowercase ? item.targetText : item.adjustedTargetTextForExercise((ExerciseUI.exercise))}
                      </HighlightedWord>
                  }
                  {
                    !item.selected && this.props.inputType !== 'highlight' &&
                      <NotSelected
                        className='not-selected'
                        height={this.props.inDeck ? '50px' : '40px'}
                      >
                        {this.props.sentence.simpleWord && AppUI.siteIsDefault ? item.targetText : item.adjustedTargetTextForExercise((ExerciseUI.exercise))}
                      </NotSelected>
                  }
                  {
                    item.punctuation && !this.props.sentence.rightToLeftLanguage &&
                      <Punctuation>
                        {item.punctuation}
                      </Punctuation>
                  }
                </WordWrapper>
              )})
          }
        </SentenceWrapper>
        {
          this.props.isFocused && AppUI.user.showSpecialCharacters &&
            <CharacterWrapper>
              <SpecialCharacterButtons
                showCloseButton
                languageId={ExerciseUI.groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca' ? 'DE' : null}
                onClick={this.insertText}
              />
            </CharacterWrapper>
        }
        {
          this.props.revealed && this.props.showAlternateTextFeedback &&
            <Text
              fontSize="14px"
              style={{fontWeight: 'normal'}}
              color={Theme.green}
              margin="10px 0 0 0"
            >
              Another possible answer is: <i>{this.props.sentence.targetText}</i>
            </Text>
        }
      </Wrapper>
    );
  }
}

export default ExerciseSentence;
